import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { prefix } from "../components/offer-section.utils";
import offerDetailsCss from "../components/offer-details.module.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import Showcase from "../components/showcase";

// style
const pStyles = {
  lineHeight: "1.4rem",
  margin: "1rem 0",
};

const background = "#20222d";

// data
const meta = [
  {
    img: "roleta-6",
    text: {
      title: "Roleta Dzień i Noc w kasecie",
      description: "Świetne rozwiązanie dla nowoczesnych mieszkań.",
    },
  },
  {
    img: "roleta-1",
    text: {
      title: "Klasyczna roleta w kasecie",
      description:
        "Szeroka gama materiałów może nadać odpowiedni nastrój wnętrzu. Od rustykalnego przez industrialny po glamour.",
    },
  },
  {
    img: "roleta-3",
    text: {
      title: "Rewelacyjne przysłonięcie dużej powierzchni",
      description: "Brak opisu",
    },
  },
  {
    img: "roleta-4",
    text: {
      title: "Efektowny design",
      description: "Brak opisu",
    },
  },
  {
    img: "roleta-2",
    text: {
      title: "Roleta wolnowisząca",
      description: "Brak opisu",
    },
  },
  {
    img: "roleta-5",
    text: {
      title: "Możliwość dowolnego montażu kasety",
      description: "Brak opisu",
    },
  },
];

// markup
const RoletyPage = () => {
  // data
  const descriptions = [
    "Nasze rolety wewnętrzne wyróżniają się na rynku szeroką paletą materiałów oraz trwałością wykonania. Rolety pozwalają niezwykle szybko zaciemnić lub rozjaśnić tudzież delikatnie doświetlić pomieszczenie. Ich wyróżnikiem jest bardzo skuteczne zatrzymywanie promieni słonecznych, dzięki czemu unikamy nagrzewania się pomieszczeń.",
    "W naszej ofercie posiadamy materiały o różnej przepuszczalności światła, dzięki czemu z pewnością znajdą Państwo wymarzony typ. Oferujemy zarówno tradycyjne, minimalistyczne modele, które idealnie odnajdą się w nowoczesnych wnętrzach, jak i bogato zdobione wyroby. Produkujemy zarówno rolety w kasetach jak i wolno-wiszące, w prowadnicach i bez. Kolor elementów stałych dobieramy pod stolarkę.",
  ];

  const { images } = useStaticQuery(graphql`
    query RoletyImages {
      images: allFile(
        filter: { relativeDirectory: { eq: "showcase/rolety" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  const final = images.edges.map((edge) => {
    const texts = meta.find((m) => m.img === edge?.node?.name);
    edge.node.meta = { ...texts.text };
    return edge;
  });
  return (
    <Layout>
      <SEO
        title="Rolety Lider | Rolety | Rolety dzień noc | Rolety drewniane"
        description="Rolety w szerokiej gamie kolorów i materiałów. Tylko sprawdzeni producenci."
      />
      <section style={{ marginTop: "-6.1rem" }}>
        {prefix(true, background)}
        <article
          style={{
            background,
          }}
          className={offerDetailsCss.article}
        >
          <PageTitle showBack="true" title="Rolety" />
          <div className={offerDetailsCss.text}>
            {descriptions.map((text, i) => (
              <p key={i} style={pStyles}>
                {text}
              </p>
            ))}
          </div>
          <Showcase images={final} />
        </article>
      </section>
    </Layout>
  );
};

export default RoletyPage;
